<template>
    <client-page>
        <mypage-layout title="주문내역">
            <page-section-primary class="page-section--xs py-0">
                <template #pageSectionFoot>
                    <!-- <u-txt-default class="txt--sm mb-20px">상품권 재발송은 한번만 사용 가능합니다.</u-txt-default> -->

                    <v-simple-table class="v-table--board table-fixed text-center">
                        <thead>
                            <tr>
                                <th width="20%;" class="text-center">주문번호</th>
                                <th width="24%;" class="text-center">상품명</th>
                                <th width="14%;" class="text-center">주문금액</th>
                                <th width="12%;" class="text-center">주문일자</th>
                                <th width="10%;" class="text-center">결제상태</th>
                                <th width="10%;" class="text-center">발행상태</th>
                                <th width="10%;" class="text-center">취소요청</th>
                                <th width="10%;" class="text-center">재발송</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="order in orders" :key="order._id">
                                <td style="word-break: break-all;">{{ order.orderNo }}</td>
                                <td class="v-table--board__tit justify-center">
                                    <div v-for="purchase in order.purchases" :key="purchase._id" class="d-flex text-truncate">
                                        <span><v-img :src="purchase?.thumb?.src" max-width="48" max-height="48"></v-img></span>
                                        <span v-if="purchase.brandName">{{ `[${purchase?.brandName}]` }}</span><span>{{ purchase?.name }}</span>
                                    </div>
                                </td>
                                <td>
                                    <div v-for="purchase in order.purchases" :key="purchase._id">{{ purchase.saleAmount.format() }}원</div>
                                </td>
                                <td>
                                    <div v-for="purchase in order.purchases" :key="purchase._id">{{ $dayjs(purchase.createdAt).format("YYYY-MM-DD hh:mm:ss") }}</div>
                                </td>
                                <td>
                                    <div v-for="purchase in order.purchases" :key="purchase._id">{{ purchase.paymentStatus }}</div>
                                </td>
                                <td>
                                    <div v-for="purchase in order.purchases" :key="purchase._id">{{ purchase.status === "취소반려" ? "사용완료" : purchase.status }}<span v-if="purchase.status === '취소반려'"><v-icon @click="$refs.cancelRejectDialog.open">mdi-help-circle-outline</v-icon></span></div>
                                </td>
                                <td>
                                    <div v-for="purchase in order.purchases" :key="purchase._id">
                                        <v-chip :disabled="isCancelDisabled(purchase)" size="x-small" style="min-width: 56px" @click="cancel(purchase)">취소요청</v-chip>
                                    </div>
                                    <!-- <chip-secondary2 size="x-small" style="min-width: 56px">불가</chip-secondary2> -->
                                </td>
                                <td>
                                    <v-chip :disabled="!order.resendable" size="x-small" style="min-width: 56px" @click="resend(order)">재발송</v-chip>
                                    <!-- <chip-secondary2 size="x-small" style="min-width: 56px">불가</chip-secondary2> -->
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>

                    <div class="pagination-wrap">
                        <v-pagination v-model="page" :length="pageCount" total-visible="11" @update:model-value="search(true)" />
                    </div>
                </template>
            </page-section-primary>
            <cancel-reject-dialog ref="cancelRejectDialog" />
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import { attrs_input, btn_primary, chip_primary, chip_primary2, chip_primary3, card_secondary, table_primary } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import TxtSecondary from "@/components/publish/parents/typography/txt-secondary.vue";

import BtnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";
import BtnSecondary3 from "@/components/publish/parents/buttons/btn-secondary3.vue";
import RadioPrimary from "@/components/publish/parents/forms/radio-primary.vue";
import CheckboxPrimary from "@/components/publish/parents/forms/checkbox-primary.vue";
import TextFieldPrimary from "@/components/publish/parents/forms/text-field-primary.vue";
import FileInputPrimary from "@/components/publish/parents/forms/file-input-primary.vue";
import SelectPrimary from "@/components/publish/parents/forms/select-primary.vue";
import ComboboxPrimary from "@/components/publish/parents/forms/combobox-primary.vue";
import TextareaPrimary from "@/components/publish/parents/forms/textarea-primary.vue";
import PageSectionPrimary from "@/sets/parents/pages/page-section-primary.vue";
import CancelRejectDialog from "@/components/client/mypage/cancel-reject-dialog.vue";

const headers = [
    { width: "10%", align: "center", text: "주문번호/발행일", value: "purchaseNo" },
    // { width: "16%", align: "center", text: "발행일", value: "createdAt" },
    { width: "15%", align: "center", text: "수신인/전화번호", value: "receiver.name" },
    // { width: "14%", align: "center", text: "전화번호", value: "receiver.phone" },
    { width: "15%", align: "center", text: "상품명", value: "orderName" },
    { width: "12%", align: "center", text: "액면가", value: "faceamt" },
    { width: "10%", align: "center", text: "상태값", value: "statusMessage" },
    { width: "10%", align: "center", text: "발송 상태값", value: "pin.sendStatus" },
    { width: "16%", align: "center", text: "발송시간", value: "reserveDate" },
    { width: "15%", align: "center", text: "만료일", value: "expiredAt" },
];

const orderHeaders = [
    { width: "10%", align: "center", text: "주문번호", value: "orderNo" },
    { width: "15%", align: "center", text: "주문명", value: "orderName" },
    { width: "15%", align: "center", text: "권종", value: "faceamt" },
    { width: "15%", align: "center", text: "수량", value: "quantity" },
    { width: "15%", align: "center", text: "구매일", value: "createdAt" },
    { width: "10%", align: "center", text: "상세보기", value: "action" },
];

export default {
    components: {
        ClientPage,
        MypageLayout,
        PageSection,
        VDateField,
        TxtSecondary,
        MypageLayout,

        BtnPrimary,
        BtnSecondary3,
        RadioPrimary,
        CheckboxPrimary,
        TextFieldPrimary,
        FileInputPrimary,
        SelectPrimary,
        ComboboxPrimary,
        TextareaPrimary,
        PageSectionPrimary,
        CancelRejectDialog,
    },

    data() {
        return {
            attrs_input,
            btn_primary,
            chip_primary,
            chip_primary2,
            chip_primary3,
            card_secondary,
            table_primary,

            purchases: [],
            orders: [],

            limit: 10,
            summary: { totalCount: 0 },

            filter: {
                searchKey: null,
                searchValue: null,
                faceamt: null,
            },

            loading: false,

            headers,
            orderHeaders,

            statusMessageItems: [
                { text: "전체", value: null },
                { text: "사용완료", value: "사용" },
                { text: "폐기", value: "폐기" },
                { text: "미사용", value: "발행" }
            ],

            faceamtItems: [
                { text: "전체", value: null },
                { text: "5,000원", value: 5000 },
                { text: "10,000원", value: 10000 },
                { text: "30,000원", value: 30000 },
                { text: "50,000원", value: 50000 },
            ],

            searchKeys: [
                { text: "전체", value: null },
                { text: "주문번호", value: "orderNo" },
                { text: "주문명", value: "orderName" },
            ]
        }
    },
    computed: {
        items() {
            return [...this.orders].map((item, index) => ({ index: this.summary.totalCount - (this.page - 1) * this.limit - index, ...item }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init(){
            await this.search(false);
        },
        async resend(order){
            if (confirm("재발송 요청은 1회만 가능합니다")) {
                await api.v1.user.me.gshop2.orders.resend(order);
                alert("재발송 요청 되었습니다");
                await this.init();
            }
        },

        async search(routable = false){
            if(routable){
                this.$router.push({
                    query: { ...this.filter, page: this.page }
                });
            }

            let { summary, orders } = await api.v1.user.me.gshop2.orders.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                },
                params: this.filter
            });

            this.pageCount = Math.ceil(summary.totalCount / this.limit);
            this.orders = orders;
        },

        async cancel(purchase){
            await api.v1.user.me.gshop2.purchases.cancel.post(purchase);
            alert("취소요청 되었습니다");
            purchase.status = "취소요청";
            purchase.cancelable = false;
        },
        isCancelDisabled(purchase) {
            const statusMap = {
                "취소요청": true,
                "취소완료": true,
                "취소반려": true,
                "사용완료": true,
            }
            return statusMap[purchase.status];
        }
    },
};
</script>
