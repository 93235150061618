var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    on: {
      "update:model-value": _vm.emit
    },
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', {
    attrs: {
      "rounded": "0"
    }
  }, [_c('v-toolbar', {
    attrs: {
      "density": "comfortable"
    }
  }, [_c('v-spacer'), _c('v-btn', {
    staticClass: "v-btn--size-xx-large",
    attrs: {
      "icon": "",
      "rounded": "0"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.hide.apply(null, arguments);
      }
    }
  }, [_c('u-icon', [_vm._v("close")])], 1)], 1), _c('div', {
    staticClass: "px-20px pb-20px px-md-60px pt-md-12px pb-md-80px"
  }, [_vm.title ? _c('tit', {
    staticClass: "tit-wrap--xs text-center mb-20px mb-md-40px"
  }, [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _vm._t("default")], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }