var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('mypage-layout', {
    attrs: {
      "title": "주문내역"
    }
  }, [_c('page-section-primary', {
    staticClass: "page-section--xs py-0",
    scopedSlots: _vm._u([{
      key: "pageSectionFoot",
      fn: function () {
        return [_c('v-simple-table', {
          staticClass: "v-table--board table-fixed text-center"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "20%;"
          }
        }, [_vm._v("주문번호")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "24%;"
          }
        }, [_vm._v("상품명")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "14%;"
          }
        }, [_vm._v("주문금액")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "12%;"
          }
        }, [_vm._v("주문일자")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%;"
          }
        }, [_vm._v("결제상태")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%;"
          }
        }, [_vm._v("발행상태")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%;"
          }
        }, [_vm._v("취소요청")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%;"
          }
        }, [_vm._v("재발송")])])]), _c('tbody', _vm._l(_vm.orders, function (order) {
          return _c('tr', {
            key: order._id
          }, [_c('td', {
            staticStyle: {
              "word-break": "break-all"
            }
          }, [_vm._v(_vm._s(order.orderNo))]), _c('td', {
            staticClass: "v-table--board__tit justify-center"
          }, _vm._l(order.purchases, function (purchase) {
            var _purchase$thumb;
            return _c('div', {
              key: purchase._id,
              staticClass: "d-flex text-truncate"
            }, [_c('span', [_c('v-img', {
              attrs: {
                "src": purchase === null || purchase === void 0 ? void 0 : (_purchase$thumb = purchase.thumb) === null || _purchase$thumb === void 0 ? void 0 : _purchase$thumb.src,
                "max-width": "48",
                "max-height": "48"
              }
            })], 1), purchase.brandName ? _c('span', [_vm._v(_vm._s(`[${purchase === null || purchase === void 0 ? void 0 : purchase.brandName}]`))]) : _vm._e(), _c('span', [_vm._v(_vm._s(purchase === null || purchase === void 0 ? void 0 : purchase.name))])]);
          }), 0), _c('td', _vm._l(order.purchases, function (purchase) {
            return _c('div', {
              key: purchase._id
            }, [_vm._v(_vm._s(purchase.saleAmount.format()) + "원")]);
          }), 0), _c('td', _vm._l(order.purchases, function (purchase) {
            return _c('div', {
              key: purchase._id
            }, [_vm._v(_vm._s(_vm.$dayjs(purchase.createdAt).format("YYYY-MM-DD hh:mm:ss")))]);
          }), 0), _c('td', _vm._l(order.purchases, function (purchase) {
            return _c('div', {
              key: purchase._id
            }, [_vm._v(_vm._s(purchase.paymentStatus))]);
          }), 0), _c('td', _vm._l(order.purchases, function (purchase) {
            return _c('div', {
              key: purchase._id
            }, [_vm._v(_vm._s(purchase.status === "취소반려" ? "사용완료" : purchase.status)), purchase.status === '취소반려' ? _c('span', [_c('v-icon', {
              on: {
                "click": _vm.$refs.cancelRejectDialog.open
              }
            }, [_vm._v("mdi-help-circle-outline")])], 1) : _vm._e()]);
          }), 0), _c('td', _vm._l(order.purchases, function (purchase) {
            return _c('div', {
              key: purchase._id
            }, [_c('v-chip', {
              staticStyle: {
                "min-width": "56px"
              },
              attrs: {
                "disabled": _vm.isCancelDisabled(purchase),
                "size": "x-small"
              },
              on: {
                "click": function ($event) {
                  return _vm.cancel(purchase);
                }
              }
            }, [_vm._v("취소요청")])], 1);
          }), 0), _c('td', [_c('v-chip', {
            staticStyle: {
              "min-width": "56px"
            },
            attrs: {
              "disabled": !order.resendable,
              "size": "x-small"
            },
            on: {
              "click": function ($event) {
                return _vm.resend(order);
              }
            }
          }, [_vm._v("재발송")])], 1)]);
        }), 0)]), _c('div', {
          staticClass: "pagination-wrap"
        }, [_c('v-pagination', {
          attrs: {
            "length": _vm.pageCount,
            "total-visible": "11"
          },
          on: {
            "update:model-value": function ($event) {
              return _vm.search(true);
            }
          },
          model: {
            value: _vm.page,
            callback: function ($$v) {
              _vm.page = $$v;
            },
            expression: "page"
          }
        })], 1)];
      },
      proxy: true
    }])
  }), _c('cancel-reject-dialog', {
    ref: "cancelRejectDialog"
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }