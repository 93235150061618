<template>
    <u-file-input-outlined-tile :label="$t(label)">
        <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
            <slot :name="slotName" />
        </template>
    </u-file-input-outlined-tile>
</template>

<script>
import UFileInputOutlinedTile from "@/components/publish/styles/forms/u-file-input-outlined-tile.vue";

export default {
    props: {
        label: { type: String, default: "파일을 첨부하세요" },
    },
    components: {
        UFileInputOutlinedTile,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
