<template>
    <u-select-outlined-rounded :placeholder="placeholder">
        <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
            <slot :name="slotName" />
        </template>
    </u-select-outlined-rounded>
</template>

<script>
import USelectOutlinedRounded from "@/components/publish/styles/forms/u-select-outlined-rounded.vue";

export default {
    props: {
        placeholder: { type: String, default: "선택" },
    },
    components: {
        USelectOutlinedRounded,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
