var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-dialog-flat-tile', {
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('div', [_vm._v("[ 취소 요청이 반려되었습니다. 사용이 된 상품으로 취소가 불가합니다.")]), _c('div', [_vm._v("자세한 사항은 고객센터로 연락주세요. 1544-5218]")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }