<template>
    <v-file-input v-bind="{ ...attrs_file_input_common, ...$attrs }" class="rounded-0">
        <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
            <slot :name="slotName" />
        </template>
    </v-file-input>
</template>

<script>
import { attrs_file_input_common } from "@/assets/variables";

export default {
    props: {},
    components: {},
    data() {
        return {
            attrs_file_input_common,
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
:deep() {
    .v-field--active {
        .v-label.v-field-label.v-field-label--floating {
            width: 0;
            margin: 0 !important;
        }
        .v-field__input {
            align-items: center;
        }
    }
}
</style>
