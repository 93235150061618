<template>
    <u-dialog-flat-tile v-model="dialog">
        <div>[ 취소 요청이 반려되었습니다. 사용이 된 상품으로 취소가 불가합니다.</div>
        <div>자세한 사항은 고객센터로 연락주세요. 1544-5218]</div>
    </u-dialog-flat-tile>
</template>

<script>
import UDialogFlatTile from "@/components/publish/styles/dialogs/u-dialog-flat-tile.vue";
export default {
    components: {
        UDialogFlatTile,
    },
    data() {
        return {
            dialog: false,
        };
    },
    methods: {
        open() {
            this.dialog = true;
        },
        close() {
            this.dialog = false;
        },
    },
};
</script>

<style>
</style>