<template>
    <v-btn v-bind="$attrs" variant="outlined" rounded :color="color" :class="[color == 'grey' ? 'border-color border-grey-lighten-3' : '']">
        <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
            <slot :name="slotName" />
        </template>
    </v-btn>
</template>

<script>
export default {
    props: {
        color: { type: String, default: "grey" },
    },
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
