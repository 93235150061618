<template>
    <v-dialog v-model="shows" @update:model-value="emit">
        <v-card rounded="0">
            <v-toolbar density="comfortable">
                <v-spacer></v-spacer>
                <!-- <v-btn icon rounded="0" class="v-btn--size-xx-large" @click.stop="$emit('close')"><u-icon>close</u-icon></v-btn> -->
                <v-btn icon rounded="0" class="v-btn--size-xx-large" @click.stop="hide"><u-icon>close</u-icon></v-btn>
            </v-toolbar>
            <div class="px-20px pb-20px px-md-60px pt-md-12px pb-md-80px">
                <tit v-if="title" class="tit-wrap--xs text-center mb-20px mb-md-40px">{{ title }}</tit>
                <slot />
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import UIcon from "@/components/publish/styles/icons/u-icon.vue";
import Tit from "@/components/publish/styles/typography/tit.vue";

export default {
    props: {
        title: { type: String, default: "" },
        modelValue: { type: String, default: "" },
    },
    components: {
        UIcon,
        Tit,
    },
    data: () => ({
        shows: false,
    }),
    methods: {
        sync() {
            this.shows = this.modelValue;
        },
        emit() {
            this.$emit("update:model-value", this.shows);
        },
        hide() {
            this.shows = false;
            this.emit();
        },
    },
    watch: {
        modelValue() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style lang="scss" scoped></style>
