<template>
    <u-btn-outlined-rounded color="secondary">
        <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
            <slot :name="slotName" />
        </template>
    </u-btn-outlined-rounded>
</template>

<script>
import UBtnOutlinedRounded from "@/components/publish/styles/buttons/u-btn-outlined-rounded.vue";

export default {
    props: {},
    components: {
        UBtnOutlinedRounded,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
